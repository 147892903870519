import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import { Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import { ChevronLeft } from 'react-feather';
import { RenderIf } from 'components/layout';
import { Bold } from 'styles/Commons';
import UserService from 'services/UserService';
import * as S from '../styles';

enum RECOVERY_VIEW {
	EMAIL_VIEW = 'EMAIL_VIEW',
	CODE_VIEW = 'CODE_VIEW',
	PASSWORD_VIEW = 'PASSWORD_VIEW',
}

const PasswordRecovery = ({ backFn, recoverySuccessFn }: any) => {
	const captchaRef = useRef(null);
	const [captchaToken, setCaptchaToken] = useState('');
	const [currentView, setCurrentView] = useState(RECOVERY_VIEW.EMAIL_VIEW);

	const [email, setEmail] = useState({ value: '', hasError: false });
	const [emailForm, setEmailForm] = useState({ invalid: true });
	const [isSendingCode, setIsSendingCode] = useState(false);
	const [token, setToken] = useState('');

	const [verificationCode, setVerificationCode] = useState({ value: '', hasError: false });
	const [codeForm, setCodeForm] = useState({ invalid: true });
	const [isValidatingCode, setIsValidatingCode] = useState(false);
	const [captchaCodeToken, setCaptchaCodeToken] = useState('');
	const captchaCodeRef = useRef(null);
	const [showCodeCaptcha, setShowCodeCaptcha] = useState(false);

	const [password, setPassword] = useState({ value: '', hasError: false });
	const [confirmPassword, setConfirmPassword] = useState({ value: '', hasError: false });
	const [passwordForm, setPasswordForm] = useState({ invalid: true });
	const [isChangingPassword, setIsChangingPassword] = useState(false);

	useEffect(() => {
		const isFormInvalid = (!email.value || email.hasError);
		setEmailForm({ invalid: isFormInvalid });
	}, [email]);

	useEffect(() => {
		const isFormInvalid = (!verificationCode.value || verificationCode.hasError);
		setCodeForm({ invalid: isFormInvalid });
	}, [verificationCode]);

	useEffect(() => {
		const isFormInvalid = (!password.value || password.hasError || !confirmPassword.value || confirmPassword.hasError);
		setPasswordForm({ invalid: isFormInvalid });
	}, [password, confirmPassword]);

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => ({
			hasError: ev.target.value.length === 0 || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			value: ev.target.value
		}));
	}

	const changePassword = (ev: any) => {
		ev.persist();
		setPassword(() => ({
			hasError: ev.target.value.length < 6,
			value: ev.target.value
		}));
		checkPasswords(ev.target.value);
	}

	const changeConfirmPassword = (ev: any) => {
		ev.persist();
		setConfirmPassword(() => ({
			hasError: ev.target.value.length < 6 || password.value !== ev.target.value,
			value: ev.target.value
		}));
	}

	const changeVerificationCode = (ev: any) => {
		ev.persist();
		setVerificationCode(() => ({
			hasError: ev.target.value.length !== 6,
			value: ev.target.value
		}));
	}

	const checkPasswords = (currentPassword: string) => {
		setConfirmPassword(old => ({
			...old,
			hasError: old.value.length < 6 || currentPassword !== old.value,
		}));
	}

	const validateEmail = (ev: any, isResending = false) => {
		ev.preventDefault();
		setIsSendingCode(() => true);
		UserService.sendRecoveryPasswordCode(email.value, captchaToken)
			.then(({ data }) => {
				setToken(() => data);
				setCurrentView(() => RECOVERY_VIEW.CODE_VIEW);
				if (isResending) {
					toast('Código reenviado com sucesso.', { type: toast.TYPE.SUCCESS });
					resetCodeRecaptcha();
				}
			})
			.catch(({ data }: any) => {
				resetRecaptcha();
				toast(data.message, { type: toast.TYPE.ERROR });
			})
			.finally(() => setIsSendingCode(() => false));
	}

	const validateCode = (ev: any) => {
		ev.preventDefault();
		setIsValidatingCode(() => true);

		UserService.validateRecoveryPasswordCode(token, verificationCode.value, captchaCodeToken)
			.then(({ data }) => {
				if (data) {
					setCurrentView(() => RECOVERY_VIEW.PASSWORD_VIEW);
				}
			})
			.catch(({ data }: any) => {
				toast(data.message, { type: toast.TYPE.ERROR });
				setVerificationCode(() => ({ value: '', hasError: false }));
				setCodeForm(() => ({ invalid: true }));
				resetCodeRecaptcha();
				setShowCodeCaptcha(true);
			})
			.finally(() => setIsValidatingCode(() => false));
	}

	const validatePassword = (ev: any) => {
		ev.preventDefault();
		setIsChangingPassword(() => true);

		const payload = {
			password: password.value,
			confirmPassword: confirmPassword.value,
			code: verificationCode.value,
		}

		UserService.changePassword(token, payload)
			.then(({ data }) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				recoverySuccessFn();
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsChangingPassword(() => false));
	}

	const backView = () => {
		if (currentView === RECOVERY_VIEW.EMAIL_VIEW) {
			backFn();
			return;
		}

		if (currentView === RECOVERY_VIEW.CODE_VIEW) {
			setCurrentView(() => RECOVERY_VIEW.EMAIL_VIEW);
			setVerificationCode(() => ({ value: '', hasError: false }));
			setCodeForm(() => ({ invalid: true }));
			return;
		}

		if (currentView === RECOVERY_VIEW.PASSWORD_VIEW) {
			setCurrentView(() => RECOVERY_VIEW.CODE_VIEW);
		}
	}

	const resetRecaptcha = () => {
		setCaptchaToken('');
		//@ts-ignore
		captchaRef?.current?.reset();
	}

	const resetCodeRecaptcha = () => {
		setCaptchaCodeToken('');
		//@ts-ignore
		captchaCodeRef?.current?.reset();
	}

	return (
		<>
			<S.Header>
				<S.ReturnButton onClick={backView}>
					<ChevronLeft size="1rem" />
				</S.ReturnButton>
				<S.Title>Esqueci minha senha</S.Title>
			</S.Header>

			<RenderIf condition={currentView === RECOVERY_VIEW.EMAIL_VIEW}>
				<S.SmallText>Digite o e-mail que deseja recuperar a senha</S.SmallText>

				<form onSubmit={validateEmail}>
					<InputWrapper hasError={email.hasError}>
						<Label data-required>E-mail</Label>
						<Input id="email" placeholder="seunome@email.com" value={email.value} onChange={changeEmail} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<ReCAPTCHA
						sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
						ref={captchaRef}
						onChange={($event) => setCaptchaToken($event || '')}
						onExpired={resetRecaptcha}
					/>

					<Button width="100%" margin="2.25rem 0 0" loading={isSendingCode} disabled={emailForm.invalid || !captchaToken}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === RECOVERY_VIEW.CODE_VIEW}>
				<S.SmallText>Insira o código de verificação enviado para <Bold>{email.value}</Bold> para redefinir sua senha</S.SmallText>

				<form onSubmit={validateCode}>
					<InputWrapper hasError={verificationCode.hasError}>
						<Label data-required>Código de verificação</Label>
						<MaskInput id="verificationCode" mask="******" value={verificationCode.value} maskPlaceholder={null} placeholder="000000" onChange={changeVerificationCode} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<S.SmallText>Não recebeu o código? <S.ResendCode onClick={$event => validateEmail($event, true)}>Reenviar código</S.ResendCode></S.SmallText>

					<RenderIf condition={showCodeCaptcha}>
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
							ref={captchaCodeRef}
							onChange={($event) => setCaptchaCodeToken($event || '')}
							onExpired={resetCodeRecaptcha}
						/>
					</RenderIf>

					<Button width="100%" margin="2.25rem 0 0" loading={isValidatingCode} disabled={codeForm.invalid}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === RECOVERY_VIEW.PASSWORD_VIEW}>
				<S.SmallText>Digite sua nova senha para continuar</S.SmallText>
				<form onSubmit={validatePassword}>
					<InputWrapper hasError={password.hasError}>
						<Label data-required>Senha</Label>
						<Input id="password" type="password" value={password.value} onChange={changePassword} />
						<InputErrorMessage>Senha deve ter no mínimo 6 caracteres</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={confirmPassword.hasError}>
						<Label data-required>Confirmar senha</Label>
						<Input id="confirmPassword" type="password" value={confirmPassword.value} onChange={changeConfirmPassword} />
						<InputErrorMessage>As senhas são diferentes</InputErrorMessage>
					</InputWrapper>

					<Button width="100%" margin="2.25rem 0 0" loading={isChangingPassword} disabled={passwordForm.invalid}>Alterar senha</Button>
				</form>
			</RenderIf>
		</>
	);
}

export default PasswordRecovery;
