import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import AuthService from 'services/AuthService';
import UserService from 'services/UserService';
import { Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import { ChevronLeft } from 'react-feather';
import * as S from '../styles';
import { RenderIf } from 'components/layout';
import UserContext from 'context/user';
import PhoneAuthResendCode from '../PhoneAuthResendCode/PhoneAuthResendCode';

const CODE_SIZE = 6;

enum STEP_VIEW {
	PHONE_VIEW = 'PHONE_VIEW',
	CODE_VIEW = 'CODE_VIEW',
}

const PhoneAuth = ({ showBackButton = true, loginSuccessFn, changeTypeFn, backFn, registerFn }: any) => {
	const { setState } = useContext(UserContext);

	const [currentView, setCurrentView] = useState(STEP_VIEW.PHONE_VIEW);
	const [isLoading, setIsLoading] = useState(false);
	const [phone, setPhone] = useState({ value: '', hasError: false });
	const [code, setCode] = useState({ value: '', hasError: false });

	const changePhone = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);
		setPhone(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const validatePhone = (ev: any) => {
		ev.preventDefault();
		sendCode();
	}

	const changeCode = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/[^0-9]/g, '').substring(0, CODE_SIZE);
		setCode(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < CODE_SIZE,
			value: cleanValue
		}));
	}

	const sendCode = () => {
		const params = { phone: phone.value };
		UserService.sendWhatsappAuthCode(params)
			.then(({ data }: any) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				setCurrentView(() => STEP_VIEW.CODE_VIEW)
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const resendCode = () => {
		const params = { phone: phone.value };
		UserService.resendWhatsappAuthCode(params)
			.then(({ data }: any) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				setCurrentView(() => STEP_VIEW.CODE_VIEW)
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const login = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);

		if (AuthService.isAuthenticated()) {
			getUserData(AuthService.getAccessToken());
			return;
		}

		AuthService.login(phone.value, code.value, 'PHONE')
			.then(({ data: token }: any) => setToken(token))
			.catch(({ data }: any) => {
				toast(data.detail, { type: toast.TYPE.ERROR });
				setIsLoading(() => false);
			});
	}

	const setToken = async (token: any) => {
		await AuthService.setToken(token);
		getUserData(token.access_token);
	}

	const getUserData = (accessToken: string) => {
		AuthService.getUserData(accessToken)
			.then(({ data }: any) => {
				AuthService.setUserData(data);
				setUserOnContext(data);
				if (!localStorage.getItem('IS_DATA_COMPLETED')) {
					localStorage.setItem('IS_DATA_COMPLETED', data.isDataCompleted);
				}
				loginSuccessFn('PHONE');
			})
			.catch((err: any) => console.log(err))
			.finally(() => setIsLoading(() => false));
	}

	const setUserOnContext = ((user: any) => {
		setState({
			isLogged: true,
			firstName: user.firstName,
			lastName: user.lastName,
			photoUrl: user.photoUrl,
			username: user.username,
			isDataCompleted: user.isDataCompleted,
		});
	})

	const backHandler = () => {
		if (currentView === STEP_VIEW.PHONE_VIEW) {
			backFn();
			return;
		}
		setCurrentView(STEP_VIEW.PHONE_VIEW);
	}

	return (
		<>
			<S.Header>
				<RenderIf condition={showBackButton || currentView === STEP_VIEW.CODE_VIEW}>
					<S.ReturnButton onClick={backHandler}>
						<ChevronLeft size="1rem" />
					</S.ReturnButton>
				</RenderIf>
				<S.Title>Acesse sua conta</S.Title>
			</S.Header>

			<RenderIf condition={currentView === STEP_VIEW.PHONE_VIEW}>
				<form onSubmit={validatePhone}>
					<InputWrapper hasError={phone.hasError}>
						<Label>Telefone</Label>
						<MaskInput
							id="phone"
							mask="(99) 99999-9999"
							value={phone.value}
							maskPlaceholder={null}
							placeholder="(99) 99999-9999"
							onChange={changePhone}
						/>
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<Button
						width="100%"
						margin="2.25rem 0 0"
						loading={isLoading}
						disabled={phone.value.length === 0 || phone.hasError}>Acessar conta</Button>

					<S.SmallTextLinkColored onClick={changeTypeFn}>Entrar com e-mail</S.SmallTextLinkColored>

					<S.SmallTextLinkColored onClick={registerFn}>Cadastre-se</S.SmallTextLinkColored>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === STEP_VIEW.CODE_VIEW}>
				<form onSubmit={login}>
					<S.SmallText>Digite o código que você recebeu por SMS ou Whatsapp.</S.SmallText>

					<InputWrapper>
						<Label>Código</Label>
						<Input id="code" placeholder="000000" value={code.value} onChange={changeCode} />
					</InputWrapper>

					<Button
						width="100%"
						margin="2.25rem 0 0"
						loading={isLoading}
						disabled={code.value.length < CODE_SIZE || code.hasError}>Verificar código</Button>
				</form>
				<PhoneAuthResendCode hasOptions={false} resendCode={resendCode} />
			</RenderIf>
		</>
	);
}

export default PhoneAuth;
