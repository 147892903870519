import { authApi, eventApi } from './Api';

const UserService = (() => {
	function register(payload: any) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.post(`/v3/public/user/signup`, payload, { headers });
	}

	function validateRegisterCode(token: any, code: any) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.get(`/v3/public/user/token/${token}/code/${code}`, { headers });
	}

	function sendRecoveryPasswordCode(email: string, recaptcha: string) {
		const params = { email };
		const headers = {
			tenant_id: process.env.REACT_APP_TENANT_ID,
			Recaptcha: recaptcha,
		};
		return authApi.get(`/v3/public/user/change-password/send-code`, { params, headers });
	}

	function validateRecoveryPasswordCode(token: any, code: any, recaptcha: string) {
		const headers = {
			tenant_id: process.env.REACT_APP_TENANT_ID,
			api_key: token,
			Recaptcha: recaptcha,
		};
		return authApi.get(`/v3/public/user/change-password/validate-code/${code}`, { headers });
	}

	function changePassword(token: any, payload: any) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID, api_key: token };
		return authApi.put(`/v3/public/user/change-password`, payload, { headers });
	}

	// Register with phone
	function checkDocumentIsValid(document: string) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.get(`/v1/public/user/check-document/${document}`, { headers });
	}

	function checkPhoneAndSendCode(phone: string) {
		// send code by whatsapp only
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.get(`/v1/public/user/send-code/phone/${phone}`, { headers });
	}

	function resendWhatsappRegisterCode(phone: string, apiKey: string) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID, api_key: apiKey };
		return authApi.get(`/v1/public/user/resend-code/phone/${phone}`, { headers });
	}

	function resendSmsRegisterCode(phone: string, apiKey: string) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID, api_key: apiKey };
		return authApi.get(`/v1/public/user/phone/${phone}/sms`, { headers });
	}

	function validatePhoneRegisterCode(params: any, apiKey: string) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID, api_key: apiKey };
		return authApi.get(`/v1/public/user/validate-code`, { params, headers });
	}

	function concludePhoneRegister(payload: any, apiKey: string) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID, api_key: apiKey };
		return authApi.post(`/v1/public/user/check-email`, payload, { headers });
	}

	function sendWhatsappAuthCode(params: any) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.post('/v3/public/user/auth/send-code', null, { headers, params });
	}

	function resendWhatsappAuthCode(params: any) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.post('/v3/public/user/auth/resend-code', null, { headers, params });
	}

	function getUserDetails() {
		return authApi.get('/v3/user/buyer-data/details');
	}

	function updateUserData(payload: any) {
		return authApi.put('/v3/user/buyer-data', payload);
	}

	return {
		register: register,
		validateRegisterCode: validateRegisterCode,
		sendRecoveryPasswordCode: sendRecoveryPasswordCode,
		validateRecoveryPasswordCode: validateRecoveryPasswordCode,
		changePassword: changePassword,

		checkDocumentIsValid: checkDocumentIsValid,
		checkPhoneAndSendCode: checkPhoneAndSendCode,
		resendWhatsappRegisterCode: resendWhatsappRegisterCode,
		resendSmsRegisterCode: resendSmsRegisterCode,
		validatePhoneRegisterCode: validatePhoneRegisterCode,
		concludePhoneRegister: concludePhoneRegister,

		sendWhatsappAuthCode: sendWhatsappAuthCode,
		resendWhatsappAuthCode: resendWhatsappAuthCode,

		getUserDetails: getUserDetails,
		updateUserData: updateUserData,
	}
})();

export default UserService;
