import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import * as S from './styles';
import { RenderIf } from 'components/layout';
import { Image } from 'styles/Commons';
import UserContext from 'context/user';
import CompleteUserForm from '../CompleteUserForm/CompleteUserForm';
import AuthService from 'services/AuthService';


const CompleteUserWarning = () => {
	const location = useLocation();
	const [showWarning, setShowWarning] = useState(false);
	const [showForm, setShowForm] = useState(false);

	const { state: userState, setState: setUserState } = useContext(UserContext);

	useEffect(() => {
		const isDataCompleted = JSON.parse(localStorage.getItem('IS_DATA_COMPLETED') as string);

		if (userState.isLogged && !isDataCompleted && !location.pathname.includes('inscricao')) {
			setShowWarning(true);
		}
	}, [userState]);

	useEffect(() => {
		if (location.pathname.includes('inscricao')) {
			setShowWarning(false);
		}
	}, [location]);

	const closeWarning = () => {
		setShowWarning(false);
	}

	const reloadUser = () => {
		// AuthService.getUserData(AuthService.getAccessToken())
		// 	.then(({ data }: any) => {
		// 		AuthService.setUserData(data);
		// 		setUserState({
		// 			isLogged: true,
		// 			firstName: data.firstName,
		// 			lastName: data.lastName,
		// 			photoUrl: data.photoUrl,
		// 			username: data.username,
		// 			isDataCompleted: data.isDataCompleted, // TODO temporário precisa atualizar pelo me
		// 		});
		// 	})
		// 	.catch((err: any) => console.log(err));
		toast('Dados completados com sucesso.', { type: toast.TYPE.SUCCESS })
		setUserState({
			...userState,
			isDataCompleted: true
		});
		localStorage.setItem('IS_DATA_COMPLETED', 'true');
		setShowWarning(false);
		setShowForm(false);
	}

	return (
		<RenderIf condition={userState.isLogged}>
			<S.CompleteUserDataWarning show={showWarning}>
				<S.CompleteUserDataContent onClick={() => setShowForm(true)}>
					<RenderIf condition={!isMobile}>
						<Image src={`${process.env.PUBLIC_URL}/images/person-and-phone.png`} height={70} />
					</RenderIf>
					<div>
						<S.CompleteUserDataTitle>Complete seu cadastro!</S.CompleteUserDataTitle>
						<S.CompleteUserDataMessage>Clique aqui e atualize sua conta e encontre os melhores eventos.</S.CompleteUserDataMessage>
					</div>
				</S.CompleteUserDataContent>
				<S.CompleteUserDataClose onClick={closeWarning}>&#x2715;</S.CompleteUserDataClose>
			</S.CompleteUserDataWarning>

			<CompleteUserForm isOpen={showForm} closeFn={() => setShowForm(false)} successFn={reloadUser} />
		</RenderIf>
	)
}

export default CompleteUserWarning;
